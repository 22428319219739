import { getCommonParamsItemLabel } from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'bankName',
      label: 'card.bank-name'
    },
    {
      prop: 'cardBin',
      label: 'card.card-bin'
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },

    {
      label: 'common.app-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
